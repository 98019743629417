<div class="fuse-horizontal-navigation-wrapper">
    @for (item of navigation; track $index) {

    <!-- Skip the hidden items -->
    @if ((item.hidden && !item.hidden(item)) || !item.hidden) {

    <!-- Basic -->
    @if (item.type === 'basic') {
    <fuse-horizontal-navigation-basic-item
        class="fuse-horizontal-navigation-menu-item"
        [item]="item"
        [name]="name"
    ></fuse-horizontal-navigation-basic-item>
    } @else if (item.type === 'aside' || item.type === 'collapsable' ||
    item.type === 'group') {
    <!-- Branch: aside, collapsable, group -->

    <fuse-horizontal-navigation-branch-item
        class="fuse-horizontal-navigation-menu-item"
        [item]="item"
        [name]="name"
    ></fuse-horizontal-navigation-branch-item
    >} @else if (item.type === 'spacer') {
    <!-- Spacer -->
    <fuse-horizontal-navigation-spacer-item
        class="fuse-horizontal-navigation-menu-item"
        [item]="item"
        [name]="name"
    ></fuse-horizontal-navigation-spacer-item>
    } } }
</div>
