@switch (layout) {
    @case ('empty') {
        <empty-layout/>
    }
    @case ('centered') {
        <centered-layout/>
    }
    @case ('enterprise') {
        <enterprise-layout/>
    }
    @case ('material') {
        <material-layout/>
    }
    @case ('modern') {
        <modern-layout/>
    }
    @case ('classic') {
        <classic-layout/>
    }
    @case ('classy') {
        <classy-layout/>
    }
    @case ('compact') {
        <compact-layout/>
    }
    @case ('dense') {
        <dense-layout/>
    }
    @case ('futuristic') {
        <futuristic-layout/>
    }
    @case ('thin') {
        <thin-layout/>
    }
}

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Settings drawer - Remove this to remove the drawer and its button -->
<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- <settings></settings> -->
<!-- NOTES: ICI POUR LES PARAMETRES -->
