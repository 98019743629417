<div class="fuse-vertical-navigation-wrapper">
    <!-- Header -->
    <div class="fuse-vertical-navigation-header">
        <ng-content select="[fuseVerticalNavigationHeader]"></ng-content>
    </div>

    <!-- Content -->
    <div
        class="fuse-vertical-navigation-content"
        fuseScrollbar
        [fuseScrollbarOptions]="{
            wheelPropagation: inner,
            suppressScrollX: true
        }"
        #navigationContent
    >
        <!-- Content header -->
        <div class="fuse-vertical-navigation-content-header">
            <ng-content
                select="[fuseVerticalNavigationContentHeader]"
            ></ng-content>
        </div>

        <!-- Items -->
        @for (item of navigation; track trackByFn) {

        <!-- Skip the hidden items -->
        @if ((item.hidden && !item.hidden(item)) || !item.hidden) { @switch
        (item.type) { @case ('aside') {
        <!-- Aside -->
        <fuse-vertical-navigation-aside-item
            [item]="item"
            [name]="name"
            [activeItemId]="activeAsideItemId || ''"
            [autoCollapse]="autoCollapse"
            [skipChildren]="true"
            (click)="toggleAside(item)"
        ></fuse-vertical-navigation-aside-item>
        } @case ('basic') {
        <!-- Basic -->
        <fuse-vertical-navigation-basic-item
            [item]="item"
            [name]="name"
        ></fuse-vertical-navigation-basic-item>
        } @case ('collapsable') {
        <!-- Collapsable -->
        <fuse-vertical-navigation-collapsable-item
            [item]="item"
            [name]="name"
            [autoCollapse]="autoCollapse"
        ></fuse-vertical-navigation-collapsable-item>
        } @case ('divider') {
        <!-- Divider -->
        <fuse-vertical-navigation-divider-item
            [item]="item"
            [name]="name"
        ></fuse-vertical-navigation-divider-item>
        } @case ('group') {
        <!-- Group -->
        <fuse-vertical-navigation-group-item
            [item]="item"
            [name]="name"
            [autoCollapse]="autoCollapse"
        ></fuse-vertical-navigation-group-item>
        } @case ('spacer') {
        <!-- Spacer -->
        <fuse-vertical-navigation-spacer-item
            [item]="item"
            [name]="name"
        ></fuse-vertical-navigation-spacer-item>
        } } } }

        <!-- Content footer -->
        <div class="fuse-vertical-navigation-content-footer">
            <ng-content
                select="[fuseVerticalNavigationContentFooter]"
            ></ng-content>
        </div>
    </div>

    <!-- Footer -->
    <div class="fuse-vertical-navigation-footer">
        <ng-content select="[fuseVerticalNavigationFooter]"></ng-content>
    </div>
</div>

<!-- Aside -->
@if (activeAsideItemId) {
<div
    class="fuse-vertical-navigation-aside-wrapper"
    fuseScrollbar
    [fuseScrollbarOptions]="{
        wheelPropagation: false,
        suppressScrollX: true
    }"
    [@fadeInLeft]="position === 'left'"
    [@fadeInRight]="position === 'right'"
    [@fadeOutLeft]="position === 'left'"
    [@fadeOutRight]="position === 'right'"
>
    <!-- Items -->
    @for (item of navigation; track trackByFn) {
    <!-- Skip the hidden items -->
    @if ((item.hidden && !item.hidden(item)) || !item.hidden) { @if (item.type
    === 'aside' && item.id === activeAsideItemId) {
    <!-- Aside -->
    <fuse-vertical-navigation-aside-item
        [item]="item"
        [name]="name"
        [autoCollapse]="autoCollapse"
    ></fuse-vertical-navigation-aside-item>
    } } }
</div>
}
